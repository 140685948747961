import { hashtagRegex, mentionRegex } from 'constants/regex';

export const isBrowser = () => typeof window !== 'undefined';

export const removeQueryStringParamsFromUrl = (pathname: string) =>
  isBrowser() && window.history.replaceState(window.history.state, '', pathname);

export const formatCompactNumber = (value: number) => {
  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
  });
  return formatter.format(value);
};

export const findHeightBasedOnAspectRatio = ({
  expectedWidth,
  originalWidth,
  originalHeight,
}: {
  expectedWidth: number;
  originalWidth: number;
  originalHeight: number;
}) => {
  return Math.round((expectedWidth * originalHeight) / originalWidth);
};

export const isMobileDevice = () => {
  if (isBrowser()) return window.innerWidth < 500;
  return false;
};

export const replaceSpacesWithUnderscores = (string: string) =>
  string.toLowerCase().replaceAll(' ', '_');

export const replaceUnderscoresWithSpaces = (string: string) =>
  string.toLowerCase().replaceAll('_', ' ');

export const extractMentions = (string: string) => {
  const mention_pattern = mentionRegex;
  const mentionList = string.match(mention_pattern);
  return mentionList;
};

export const extractHashtags = (string: string) => {
  const hashtag_pattern = hashtagRegex;
  const hashtagList = string.match(hashtag_pattern);
  return hashtagList;
};

export const splitLastOccurrence = (str: string, substring: string) => {
  const lastIndex = str.lastIndexOf(substring);
  if (lastIndex === -1) return [];

  const before = str.slice(0, lastIndex);

  const after = str.slice(lastIndex + 1);

  return [before[before.length - 1], after[0]];
};
export const truncateToTwoDecimalPlaces = (amount: number) => Math.trunc(amount * 100) / 100;

export const isBrowserFirefox = () => navigator.userAgent.indexOf('Firefox') > 0;

export const isEllipsisActive = (e: HTMLElement) => {
  var tolerance = 1; // In px. Depends on the font you are using
  return e.offsetHeight + tolerance < e.scrollHeight;
};

export const isValidArray = <T>(array: T[]): array is Array<T> => {
  return Array.isArray(array);
};

export const isListEmpty = (list: undefined | null | any[]) =>
  !Array.isArray(list) || list.length === 0;

export function isOverflown(element: HTMLElement) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export function containsNonLatinCodepoints(s: string) {
  return /[^\u0000-\u00ff]/.test(s);
}

export function getCurrentUrl() {
  return isBrowser() ? window?.location.href : '';
}

export function isUriValid(string: unknown) {
  try {
    encodeURIComponent(string as string);
    return true;
  } catch (error) {
    return false;
  }
}

export const formatNumber = (number: number): string => {
  const numberLocale = number.toLocaleString();
  const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
  const [integerPart, ...decimalParts] = numberLocale.split(decimalSeparator);

  if (decimalParts.length === 0) return numberLocale;

  const decimalPart = decimalParts.join(decimalSeparator);
  return decimalPart.length === 1
    ? number.toFixed(2).toLocaleString()
    : `${integerPart}${decimalSeparator}${decimalPart.slice(0, 2)}`;
};

type WithCatchCallback<T> = () => Promise<T>;

export const withCatchRetries = async <T>(
  fn: WithCatchCallback<T>,
  retries: number,
  retryCount: number = 0
): Promise<T> => {
  try {
    const result = await fn();
    return result;
  } catch (error) {
    if (retryCount < retries) {
      return withCatchRetries(fn, retries, retryCount + 1);
    }
    throw error;
  }
};

export const withCatchRetryInterval = async <T>(
  fn: WithCatchCallback<T>,
  interval: number = 3000,
  onError?: (error: unknown) => void
): Promise<T> => {
  try {
    const result = await fn();
    return result;
  } catch (error) {
    onError?.(error);
    await new Promise((resolve) => setTimeout(resolve, interval));
    return withCatchRetryInterval(fn, interval);
  }
};

export const withCatchRetryNetworkError = <T>(
  fn: WithCatchCallback<T>,
  interval = 3000
): Promise<T> =>
  withCatchRetryInterval(fn, interval, (error: any) => {
    if (error.code !== 'ERR_NETWORK') {
      throw error;
    }
  });
